.cosmetic_card_container {
    width: 200px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}



.cosmetic_card_image {
    height: 194px;
}

.grid-block-text-tall {
    width: 100%;
    height: 107px;
    flex-direction: column;
    align-items: flex-start;
    border-top: 3px solid #646567;
    padding: 25px 16px 30px;
    display: flex
}

.grid-block-text-tall-lower {
    width: 100%;
    border-top: 3px solid #646567;
    justify-content: center;
    padding: 10px 16px;
    display: flex
}

.cosmetic_card_detail_name {
    color: #fff;
    width: 100%;
    text-align: center;
    -webkit-text-stroke-color: transparent;
    flex: 0 auto;
    padding-bottom: 2px;
    background-color: rgba(0, 0, 0, .32);
    border: 3px solid #1e1e1f;
    border-bottom-color: #646567;
    border-right-color: #646567;
    font-family: Minecraft Seven, sans-serif;
    font-weight: 400
}



.text-block-dark {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform: scale(0.8);
    padding: 2px 0px 3px;
}

.unicode-sprite {
    height: 16px;
    background: url('https://app.zeqa.net/image/rarity/rarityunicode.webp') no-repeat;
}

.unicode-limited {
    width: 88px;
    background-position: -10px -10px;
}

.unicode-legendary {
    width: 112px;
    background-position: -10px -46px;
}

.unicode-epic {
    width: 52px;
    background-position: -10px -82px;
}

.unicode-rare {
    width: 52px;
    background-position: -10px -118px;
}

.unicode-common {
    width: 76px;
    background-position: -10px -154px;
}

.singles-lm,
.singles-l,
.singles-e,
.singles-r,
.singles-c {
    width: 42px;
    height: 42px;
    scale: 0.7;
    background: url('https://app.zeqa.net/image/rarity/rarityunicodesingles.webp');
}

.singles-lm {
    background-position: -10px -10px;
}

.singles-l {
    background-position: -10px -72px;
}

.singles-e {
    background-position: -10px -134px;
}

.singles-r {
    background-position: -10px -196px;
}

.singles-c {
    background-position: -10px -258px;
}

.cosmetic_card_preview {
    width: 194px;
    height: 197px;
    border-bottom: 3px solid #1E1E1F;
    display: flex;
    background-size: cover;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: initial;
    background: url('https://app.zeqa.net/image/rarity/raritybg.webp');

}

.cosmetic_model_container.grey,
.cosmetic_detail_left>img.common {
    background-color: whitesmoke;
}

.cosmetic_card_preview.common,
.cosmetic_model_container.common,
.cosmetic_detail_left>img.common {
    background-position: -0 194px;
    background-size: cover;
}


.cosmetic_card_preview.rare,
.cosmetic_model_container.rare,
.cosmetic_detail_left>img.rare {
    background-position: -0 388px;
    background-size: cover;
}

.cosmetic_card_preview.epic,
.cosmetic_model_container.epic,
.cosmetic_detail_left>img.epic {
    background-position: -0 582px;
    background-size: cover;
}

.cosmetic_card_preview.legendary,
.cosmetic_model_container.legendary,
.cosmetic_detail_left>img.legendary {
    background-position: -0 776px;
    background-size: cover;
}

.cosmetic_card_preview.limited,
.cosmetic_model_container.limited,
.cosmetic_detail_left>img.limited {
    background-position: -0 970px;
    background-size: cover;
}



.cosmetic_card_detail {
    text-align: center;
    padding: 10px;
}


.grid-tall-main-div {
    height: 100%;
    border-bottom: 3px solid #313235
}

.gallery-slide-wrapper {
    max-width: 400px;
    margin-right: 5%
}

.grid-tall-main-div:active {
    border-bottom-style: none;
    margin-bottom: 3px
}

.slider-wrapper {
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left;
    display: inline-block;
    position: relative
}

select {
    font-family: Minecraft Seven, sans-serif;
    font-size: 16px;
    color: #333;
}

.grid-block-div-tall {
    width: 100%;
    cursor: pointer;

    color: #313233;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
    background-color: #48494a;
    border: 3px solid #1e1e1f;
    flex-flow: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 10px;
    display: flex;
    box-shadow: 4px 4px rgba(0, 0, 0, .2)
}

.grid-block-div-tall:hover {    transition: 0.2s;

    transform: translate(-2px, -2px);
    background-color: #58585a;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block
}

.grid-block-div-tall:active {
    transform: translate(2px, 2px);
}

.cosmetic_container {
    width: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(15, 15, 15, 0.596);
    padding: 10px 20px;
    border: 3px solid #eeb42a;
    border-top-color: #de8007;
    box-shadow: 0 0px 20px 12px rgba(0, 0, 0, 0.295);
    padding: 20px
}

.cosmetic_containers {
    width: 100%;
    margin-bottom: 60px;
    border: 5px solid #1e1e1f
}

@media screen and (max-width:1279px) {
    .cosmetic_containers {
        margin-bottom: 120px;
    }
}

.cosmetic_containerss {
    z-index: 2;
    border: 4px solid #1e1e1f;
    position: absolute;
    top: 50px;
    left: 50%;
    box-shadow: 0 0px 20px 1px rgba(0, 0, 0, 0.295);

    transform: translateX(-50%);
}

.cosmetic_display_header {
    text-align: center;
    padding: 0px 30px;
}

.cosmetic_display {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: center;
    row-gap: 15px;
    column-gap: 25px;
}

.cosmetic_display.collection_row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.cosmetic_type_select {
    margin-right: 25px;

    cursor: pointer;
    color: #fff;
    font-family: Minecraft Seven;
    font-size: 18px;
    transition: all .1s;
    display: inline;
}

textarea {
    width: 100%;
    padding: 16px;
    resize: none;
    font-size: 16px;
    height: 32vh;
    margin-bottom: 24px;
    overflow: auto;
    background-color: #313233;
    border: 3px solid #1e1e1f;
    border-radius: 3px;
    box-shadow: inset 0 6px #242425;
    caret-color: #5A9642;
    color: #ffffff;
    font-family: Minecraftia, 'Roboto', sans-serif;
}

.cosmetic_type_select.active,
.cosmetic_type_select:hover {
    color: #FFCA2B;
}

.cosmetic_model_container {
    width: 300px;
    height: 300px;
    border: 5px solid;
}

.cosmetic_detail_container {
    display: inline-flex;
}

.cosmetic_detail_left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cosmetic_detail_left>img {
    border: 5px solid;
}

.cosmetic_detail_right {
    width: 50%;
    display: inline-block;
    text-align: center;
    justify-content: center;
}

@media screen and (max-width: 940px) {
    .cosmetic_detail_container {
        display: block;
    }

    .cosmetic_detail_left,
    .cosmetic_detail_right,
    .cosmetic_model_container {
        width: 100%;
    }
}

.cosmetic_display_search {
    width: 100%;

}

.horizontal-center {
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: center;
    gap: 5px;
}

.cosmetic_display_search>input {
    width: 100%;
    padding: 15px;
    resize: none;
    font-size: 16px;
    overflow: auto;
    background-color: #313233;
    border: 3px solid #1e1e1f;
    box-shadow: inset 0 6px #242425;
    caret-color: #5A9642;
    color: #ffffff;
    font-family: Minecraft Seven, sans-serif;
}

.cosmetic_display_search_wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}