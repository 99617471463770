body {
    margin: 0
}

:root {
    --white: white;
    --black: black;
}

header,
menu,
nav {
    display: block
}

[hidden] {
    display: none
}

a {
    background-color: #0000
}

a:active,
a:hover {
    outline: 0
}

button,
select {
    overflow: visible
}

button,
select {
    text-transform: none
}

button {
    appearance: button;
    cursor: pointer
}

button[disabled] {
    cursor: default
}

button::-moz-focus-inner {
    border: 0;
    padding: 0
}



html {
    height: 100%
}

body {
    color: #333;
    background-color: #fff;
    min-height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 20px
}

img {
    vertical-align: middle;
    max-width: 65%;
    border: 0;
    display: inline-block
}

.zeqa-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block
}




ul {
    margin-top: 0;
    margin-bottom: 10px;

    padding-left: 40px
}

[type=button],
[type=reset],
button {
    cursor: pointer;
    appearance: button;
    border: 0
}

label {
    margin-bottom: 5px;
    font-weight: 400;
    display: block
}

.zeqa-select {
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    height: 38px;
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block
}

.zeqa-select::placeholder {
    color: #999
}

.zeqa-select:focus {
    border-color: #3898ec;
    outline: 0
}

.zeqa-select[disabled],
.zeqa-select[readonly] {
    cursor: not-allowed
}

.zeqa-select[disabled]:not(.w-input-disabled),
.zeqa-select[readonly] {
    background-color: #eee
}

.zeqa-select {
    background-color: #f3f3f3
}

.zeqa-select[multiple] {
    height: auto
}

.zeqa-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto
}

.zeqa-container:after,
.zeqa-container:before {
    content: " ";
    grid-area: 1/1/2/2;
    display: table
}

.zeqa-container:after {
    clear: both
}


._404-stack {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

._404-container {
    max-width: 35%
}

._404-div {
    width: 80%;
    max-width: 940px;
    border: 3px solid #1e1e1f;
    margin-left: auto;
    margin-right: auto
}

._404-tile-div {
    background-color: #e0e0e0;
    border-bottom: 4px solid #919191;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

._404-heading {
    color: #000;
    text-align: center;
    margin-bottom: 0;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Minecraft Seven;
    font-size: 30px;
    font-weight: 400;
    line-height: 35px
}

._404-text {
    color: #000;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Minecraft Seven;
    font-size: 25px
}




.zeqa-nav {
    z-index: 1000;
    position: relative
}

.zeqa-nav:after,
.zeqa-nav:before {
    content: " ";
    grid-area: 1/1/2/2;
    display: table
}

.zeqa-nav:after {
    clear: both
}

.zeqa-nav-brand {
    float: left;
    color: #333;
    text-decoration: none;
    position: relative
}

.zeqa-nav-link {
    vertical-align: top;
    color: #222;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    display: inline-block;
    position: relative
}

.zeqa-nav-link.zeqa-current {
    color: #0082f3
}

.zeqa-nav-menu {
    float: right;

    position: relative
}

[data-nav-menu-open] {
    text-align: center;
    min-width: 200px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: visible;
    display: block !important
}

.zeqa-nav-overlay {
    width: 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
}


.zeqa-nav-overlay [data-nav-menu-open] {
    top: 0;
}

.zeqa-nav[data-animation="over-left"] .zeqa-nav-overlay {
    width: auto;
}

.zeqa-nav[data-animation="over-left"] .zeqa-nav-overlay,
.zeqa-nav[data-animation="over-left"] [data-nav-menu-open] {
    z-index: 1;
    left: auto;
}

.zeqa-nav-link-open {
    display: block;
    position: relative;
}


.zeqa-nav-button {
    float: right;
    cursor: pointer;
    -webkit-tap-highlight-color: #0000;
    -webkit-user-select: none;
    user-select: none;
    padding: 18px;
    font-size: 24px;
    display: none;
    position: relative
}

.zeqa-nav-button:focus {
    outline: 0
}

.zeqa-nav-button.zeqa-open {
    color: #fff;
    background-color: #25221f;
}

.zeqa-nav[data-collapse="all"] .zeqa-nav-menu {
    display: none;
}

.zeqa-nav[data-collapse="all"] .zeqa-nav-button,
.zeqa-nav-dropdown-open,
.zeqa-nav-dropdown-toggle-open {
    display: block;
}

.zeqa-nav-dropdown-list-open {
    position: static;
}




@keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.zeqa-header-nav-menu-list {
    z-index: 1;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    display: flex
}

::-webkit-scrollbar {
    width: 12px
}

::-webkit-scrollbar-track {
    background-color: #1e1e1f
}

::-webkit-scrollbar-thumb {
    background-color: #e6e8eb;
    border: 2px solid #000;
    box-shadow: inset 0 -4px #58585a, inset 0 -7px #f9fafa, inset 3px 3px #f9fafa, inset -3px 0 #f9fafa;
    min-height: 80px;
    max-height: 80px
}

.zeqa-header-logo {
    height: 72px;
    margin-left: 20px;
    max-width: 250px
}

.zeqa-header-logo-link {
    transform-style: preserve-3d;
    padding-left: 0;
    transition: transform .3s, color .3s
}

.zeqa-header-logo-link:hover {
    transform: scale3d(.96, .96, 1.01)
}

.zeqa-header-content-wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex
}

.zeqa-container-default {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    display: inline
}

.zeqa-header-wrapper {
    background-color: #141517d9;
    justify-content: space-around;
    box-shadow: 0 3px 15px 6px #00000078;
}

.zeqa-inner-container-center {
    z-index: 15;
    position: fixed;
    inset: 0 0 auto
}

.body {
    background-color: #353536
}



.zeqa-logo-center {
    text-align: center
}

.zeqa-inner-container-574px-center {
    max-width: 574px;
    margin-left: auto;
    margin-right: auto
}

.zeqa-hamburger-menu-wrapper {
    padding: 22px
}

.zeqa-hamburger-menu-wrapper:hover {
    background-color: #3C3834;
}

.login-div {
    background-color: #242424;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
}

.profile-image {
    border-radius: 6px;        

}

.navigation-links {
    color: #fff;
    text-align: center;
    font-family: Minecraft Seven, sans-serif;
    font-size: 16px;
    font-weight: 400;
    transition: all .2s;
}

.navigation-links:hover {
    color: #ffc800;
    transform: translate(-2px, -2px);
}

.nav-link {
    vertical-align: top;
    color: #ffffff;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}


@media screen and (max-width:1279px) {
    .zeqa-nav[data-collapse="medium"] .zeqa-nav-menu {
        display: none
    }

    .zeqa-header-nav-list-item {
        margin-bottom: 0;
        padding-right: 0px;
        padding-left: 32px;
    }

    .zeqa-nav[data-collapse="medium"] .zeqa-nav-button {
        display: block
    }

    .zeqa-container {
        max-width: 728px
    }

    .zeqa-header-nav-menu-list {
        flex-direction: column;
        align-items: flex-end;
    }

    .zeqa-header-menu-wrapper {
        aspect-ratio: auto;
        min-height: 100vh;
        border: 3px solid #1e1e1e;
        background-color: #313233;
        padding: 4px 24px 24px
    }
}



@media screen and (max-width:479px) {
    .zeqa-container {
        max-width: none
    }

    .zeqa-header-logo {
        max-width: 100%;
    }

    .zeqa-nav[data-collapse="tiny"] .zeqa-nav-menu {
        display: none
    }

    .zeqa-nav[data-collapse="tiny"] .zeqa-nav-button {
        display: block
    }
}