.home_container {
    width: 100%;
    background-color: rgba(122, 115, 115, 0.411);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 20px;
    margin-top: 10px;

    margin-bottom: 20px;
}

footer,
menu,
nav,
section {
    display: block
}

.select-checkbox {
    position: relative;
}


.white-button-header {
    position: relative;
    display: inline-block;
}

.toggle-dropdown {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(40px, auto);
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #313233;
    min-width: 160px;
    border: 4px solid #1E1E1F;
    z-index: 1;
    padding: 10px;
    margin-top: 8px;
    gap: 10px;
    display: none;
}

.white-button-header:hover .toggle-dropdown {
    display: grid;
}



b {
    font-weight: 400
}

small {
    font-size: 80%
}

button {
    overflow: visible
}

button {
    font-family: Minecraft Seven;
    overflow: visible;

    text-transform: none
}

p {
    margin-top: 0;
    margin-bottom: 10px
}

ol {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px
}

a {
    color: rgb(0, 119, 255);
    text-decoration: none;
    transition: all .1s;
}

a:hover {
    color: #ffca2b;
}

@media screen and (max-width:1279px) {
    .zeqa-container {
        max-width: 728px
    }

    .zeqa-nav[data-collapse=medium] .zeqa-nav-menu {
        display: none
    }

    .hero {
        min-height: 520px
    }

    .dropdown-content-wrap {
        width: 100%
    }

    .dropdown {
        z-index: 0
    }

    .zeqa-hero-image-wrapper {
        padding-top: 148px;
        padding-bottom: 148px
    }
}

.zeqa-dropdown {
    text-align: left;
    z-index: 900;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative
}

.zeqa-dropdown-toggle {
    vertical-align: top;
    color: #222;
    text-align: left;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    position: relative
}

.zeqa-dropdown-toggle {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding-right: 40px;
    display: inline-block
}

.zeqa-dropdown-toggle:focus {
    outline: 0
}

.zeqa-dropdown-list {
    background: #ddd;
    min-width: 100%;
    display: none;
    position: absolute
}

.hero {
    background-image: linear-gradient(#000, #23232500 8%);
    background-position: 0 0, 50%;
    background-repeat: repeat, repeat;
    background-size: auto, auto;
    background-attachment: scroll, scroll;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-left: 40px;
    padding-right: 40px;
    display: flex
}

.dropdown-list {
    color: #737373;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    transition: 0.6s;

    line-height: 24px;
    list-style-type: none
}

.dropdown-toggle {
    color: #000;
    background-color: #d1d0d4;
    border-bottom: 4px solid #58585a;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    display: flex
}

.dropdown-toggle:hover {
    background-color: #fdffff
}

.dropdown-toggle:active {
    background-color: #b1b3b4
}

.white-button-header {
    outline-offset: 0;
    border-bottom: 4px solid #313332;
    outline: 3px solid #1e1e1f;
    justify-content: center;
    margin-bottom: 10px;
    display: inline-block;
}

.white-button-header:active {
    border-bottom-style: none;
    margin-top: 4px;
}

.white-button {
    color: #fff;
    text-align: center;
    background-color: #48484b;
    border: 3px solid #747574;
    font-family: Open-Sans;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    padding: 10px 20px;
}

.white-button:hover {
    background-color: #59585b;
    border-width: 3px;
    border-color: #78797a;
}

.white-button:active {
    background-color: #313332;
    border-color: #5b5a5c;
}

.select-toggle {
    color: #000;
    background-color: #d1d0d4;
    border-bottom: 4px solid #58585a;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.5px;
    font-family: Minecraft Seven;
    display: flex
}

.select-toggle:active {
    margin-top: 4px;
    border-bottom: 0px solid #58585a;
}

.zeqa-select-toggle {
    vertical-align: top;
    color: #222;
    text-align: left;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    position: relative
}

.zeqa-select-toggle {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: inline-block
}

.select-toggle-title-wrapper {
    white-space: pre-wrap;
    border: 1px solid #00000000;
    background-color: #d1d0d4;
    cursor: pointer;
    justify-content: space-between;
    outline: 0;
    border: 3px solid #edecee;

    align-items: center;
    width: 100%;
    padding: 10px;
    display: flex
}

.select-toggle-title-wrapper:hover {
    background-color: #fdffff
}


.select-toggle-title-wrapper:active {
    background-color: #b1b3b4
}

.dropdown-content-wrap {
    background-color: #313233;
    width: 100%;
    max-width: 100%;
    transition: 0.6s;
    margin-bottom: 0;
    display: block;
    position: static;
    overflow: hidden
}

.dropdown-content {
    color: #737373;
    border-top: 3px solid #1e1e1f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 36px
}

.dropdown-item {
    margin-bottom: 4px;
    margin-left: 8px;
    list-style-type: square
}


.black-dropdown {
    border: 3px solid #1e1e1f;
    margin-bottom: 10px;    cursor: pointer;

    display: block
}
.black-zeqa-dropdown {
    text-align: left;
    position: relative
}
.black-dropdown-toggle {
    color: #000;
    background-color: #d1d0d4;
    border-bottom: 4px solid #313332;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    display: flex
}

.black-zeqa-dropdown-toggle {
    vertical-align: top;
    color: #222;
    text-align: left;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    position: relative
}

.black-select-toggle-title-wrapper {
    white-space: pre-wrap;
    background-color: #48484b;
    border: 3px solid #747574;
    justify-content: space-between;
    outline: 0;

    align-items: center;
    width: 100%;
    padding: 10px;
    display: flex
}

.black-select-toggle-title-wrapper:hover {
    background-color: #59585B
}


.black-select-toggle-title-wrapper:active {
    background-color: #313332
}

.dropdown {
    background-color: #0000;
    border: 3px solid #1e1e1f;
    margin-bottom: 10px;
    display: block
}

.dropdown-arrow {
    margin-right: 10px
}

.dropdown-toggle-title-wrapper {
    white-space: pre-wrap;
    border: 3px solid #edecee;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex
}

.dropdown-toggle-title {
    color: #000;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    font-family: Minecraft Five, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px
}

.dropdown-paragraph {
    color: #fff;
    font-family: Minecraft Seven, sans-serif;
    font-size: 14px;
    line-height: 20px
}


.zeqa-hero-image-wrapper {
    padding: 196px 40px;
    overflow: hidden
}

.zeqa-middle-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
    height: auto;
}



.zeqa-dropdown-centered {
    text-align: center;
    width: 60%;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto
}

.zeqa-dropdown-centered.zeqa-s-m-b-0,
.zeqa-s-m-b-0 {
    margin-bottom: 0
}



@media screen and (max-width:767px) {
    .hero {
        min-height: 420px
    }

    .zeqa-hero-image-wrapper {
        padding-top: 116px;
        padding-bottom: 116px
    }

    .zeqa-nav[data-collapse=small] .zeqa-nav-menu {
        display: none
    }

    .zeqa-nav[data-collapse=small] .zeqa-nav-button {
        display: block
    }
}

@media screen and (max-width:479px) {
    .hero {
        min-height: 340px
    }

    .zeqa-dropdown-centered {
        width: 100%
    }

    .zeqa-nav[data-collapse=tiny] .zeqa-nav-menu {
        display: none
    }

    .dropdown-toggle-title {
        margin-right: 20px;
        font-size: 16px;
        line-height: 32px
    }

    .zeqa-hero-image-wrapper {
        border-radius: 16px;
        padding-left: 18px;
        padding-right: 18px
    }
}