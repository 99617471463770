#body {
    background-color: gray;
}

#root {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

#app {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.checkbox {
    background-image: url("https://app.zeqa.net/image/toggles/toggles.webp");
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    display: flex;
    transform: scale(0.6);
}

.checkbox:hover {
    background-position-y: -64px;
}

input[type="checkbox"]:checked+.checkbox {
    background-position-y: -128px;
}

input[type="checkbox"]:checked:hover+.checkbox {
    background-position-y: -192px;
}

input[type="checkbox"] {
    display: none;
}

.select-checkbox {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    font-family: 'Minecraft Seven', sans-serif;
}

.select-checkbox label {
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
}

.fullscreen_scroll {
    position: absolute;
    top: 75px;
    bottom: 0;

    width: 100%;
    overflow: auto;
}

.notice {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255, 86, 86);
    color: white;
    height: 100px;
    z-index: 1000;
    animation: fade-out 6s forwards;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.notice h1 {
    font-family: 'Arial';
    font-size: 20px;
}

.loading-animation:after {
    content: "5";
    animation: countdown 5s steps(5, end)forwards;
}

@keyframes countdown {
    0% {
        content: "5";
    }

    20% {
        content: "4";
    }

    40% {
        content: "3";
    }

    60% {
        content: "2";
    }

    80% {
        content: "1";
    }

    100% {
        content: "0";
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none !important;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    background-image: linear-gradient(#ff9d0029 35%, #000), linear-gradient(#000000c4, #000000c4), url(https://app.zeqa.net/image/homebg.webp);
    background-position: 0 0, 0 0, 50%;
    background-size: auto, auto, cover;    
    font-family: "Prompt", sans-serif;
    height: 100vh;
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

button,
input,
option,
select,
table {
    font-family: "Prompt", sans-serif;
}

textarea:focus,
input:focus {
    outline: none;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


li {
    list-style: none;
}

.page_container {
    height: auto;
    min-height: 100%;
    padding: 20px;
    transition: all .2s;
    width: 70%;
    margin: 0 auto;
}


.search_container {
    height: auto;
    min-height: 100%;
    padding: 20px;
    transition: all .2s;
    width: 97%;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .page_container {
        width: 100%;
    }
}