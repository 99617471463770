.profile_header {

    width: 100%;
    border: 5px solid #1e1e1f;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
    margin-top: 40px;
}

.collection_row_container {
    width: 100%;
    min-height: 60px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}