.app_layout_container {
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 1;
    position: absolute;
}

.app_container {
    max-width: 100%;
    min-height: 100%;
    height: auto;
    margin: 0 auto;
}

.app_navigation_container {
    width: 100%;
    height: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 20px;
}

.app_navigation_logo {
    height: 100%;
    cursor: pointer;
}

.app_navigation_logo_img {
    height: 100%;
    width: auto;
    max-width: 250px;
}

.app_navigation_logo_text {
    font-size: 30px;
    font-weight: 400;
    color: #da202c;
}

.app_navigation_menu {
    display: flex;
    align-items: center;
}

.app_navigation_menu_select {
    display: flex;
    align-items: center;
}

.app_navigation_menu_item {
    margin-right: 25px;
    cursor: pointer;
    color: #100f0d;
    font-weight: 400;
    font-size: 18px;
    position: relative;
}

.app_navigation_menu_burger {
    display: none;
}

.app_navigation_menu_item.active,
.app_navigation_menu_item:hover {
    color: darkorange;
}

@media screen and (max-width: 1279px) {
    .app_navigation_menu_select {
        transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
        transform: translateX(100%);
        opacity: 0;
    }

    .app_navigation_menu_burger {
        float: right;
        display: block;
    }

    .app_navigation_menu_select.open {
        text-align: center;
        background: #c8c8c8;
        min-width: 200px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        overflow: visible;
        display: block !important;
        transform: translateX(0);
        opacity: 1;
    }

    .app_navigation_menu_select.open {
        transform: translateX(0);
        opacity: 1;
    }

    .app_navigation_menu_select.closing {
        transform: translateX(100%);
        opacity: 0;
    }

    .app_navigation_menu_item {
        margin-top: 25px;
        width: 100%;
        text-align: center;
    }
}




h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 10px;
    font-weight: 400;

    font-family: Minecraft Five, sans-serif;
    color: #ffffff;

}

h1 {
    margin-top: 5px;
    font-size: 28px;
    line-height: 0px;
}

h2 {
    display: flex;
    font-size: 19px;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    height: 85%;
}


h3 {
    margin-top: 20px;
    font-size: 24px;
    line-height: 30px;
}

h4 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 24px;
}

h5 {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
}

h6 {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

@font-face {
    font-family: 'Minecraft Five';
    src: url(https://app.zeqa.net/fonts/MCFIVE.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Minecraft Seven';
    src: url(https://app.zeqa.net/fonts/MCSEVEN.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}